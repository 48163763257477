import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';

const routes = [
  {
    path: '/rms',
    meta: { title: 'Records Management System', name: 'Index', parent: true },
    component: () => import('../components/Layouts/IndexLayout.vue'),
    children: [
      {
        path: '/login',
        alias: '/login',
        component: () => import('../views/LoginView.vue'),
        meta: { title: 'Login', ParentName: 'Index'}
      },
      {
        path: '/al',
        alias: '/al',
        component: () => import('../views/AdminLoginView.vue'),
        meta: { title: 'Admin Login', ParentName: 'Index'}
      },
      {
        path: '/signup',
        alias: '/signup',
        component: () => import('../views/SignUpView.vue'),
        meta: { title: 'Sign up', ParentName: 'Index'}
      }
    ]
  },
  {
    path: '/home',
    meta: { title: 'Home', name: 'Home', parent: true },
    component: () => import('../components/Layouts/DashboardLayout.vue'),
    children: [
      {
        path: '/profile',
        alias: '/profile',
        component: () => import('../views/ProfileView.vue'),
        meta: { title: 'My Profile', ParentName: 'Home'}
      },
      {
        path: '/notifications',
        alias: '/notifications',
        component: () => import('../views/NotificationsView.vue'),
        meta: { title: 'Notifications', ParentName: 'Home'}
      },
      {
        path: '/security',
        alias: '/security',
        component: () => import('../views/SecurityView.vue'),
        meta: { title: 'Security', ParentName: 'Home'}
      },
      {
        path: '/dashboard',
        alias: '/dashboard',
        component: () => import('../views/DashboardView.vue'),
        meta: { title: 'Dashboard', ParentName: 'Home'}
      },
      {
        path: '/create/:name',
        alias: '/create/:name',
        component: () => import('../views/CreateRecordView.vue'),
        meta: { title: 'Create Document', ParentName: 'Home'}
      },
      {
        path: '/workback/:name',
        alias: '/workback/:name',
        component: () => import('../views/CreateWorkbackView.vue'),
        meta: { title: 'Create Workback', ParentName: 'Home'}
      },
      {
        path: '/internal_communications/:name',
        alias: '/internal_communications/:name',
        component: () => import('../views/InternalCommunicationsView.vue'),
        meta: { title: 'Internal Communications', ParentName: 'Home'}
      },
      {
        path: '/external_communications/:name',
        alias: '/external_communications/:name',
        component: () => import('../views/ExternalCommunicationsView.vue'),
        meta: { title: 'External Communications', ParentName: 'Home'}
      },
      {
        path: '/action_slips',
        alias: '/action_slips',
        component: () => import('../views/ActionSlipsView.vue'),
        meta: { title: 'Action Slips', ParentName: 'Home'}
      },
      {
        path: '/my_letters',
        alias: '/my_letters',
        component: () => import('../views/MyLettersView.vue'),
        meta: { title: 'My Letters', ParentName: 'Home'}
      },
      {
        path: '/my_action_slips',
        alias: '/my_action_slips',
        component: () => import('../views/MyActionSlipsView.vue'),
        meta: { title: 'My Action Slips', ParentName: 'Home'}
      },
      {
        path: '/pending_documents/:type_name/:name',
        alias: '/pending_documents/:type_name/:name',
        component: () => import('../views/PendingDocumentsView.vue'),
        meta: { title: 'Pending Documents', ParentName: 'Home'}
      },
      {
        path: '/approved_documents/:type_name/:name',
        alias: '/approved_documents/:type_name/:name',
        component: () => import('../views/ApprovedDocumentsView.vue'),
        meta: { title: 'Approved Records', ParentName: 'Home'}
      },
      {
        path: '/my_uploads',
        alias: '/my_uploads',
        component: () => import('../views/MyUploadsView.vue'),
        meta: { title: 'My Uploads', ParentName: 'Home'}
      },
      {
        path: '/unread_records',
        alias: '/unread_records',
        component: () => import('../views/UnreadRecordsView.vue'),
        meta: { title: 'Unread Records', ParentName: 'Home'}
      },
      {
        path: '/read_records',
        alias: '/read_records',
        component: () => import('../views/ReadRecordsView.vue'),
        meta: { title: 'Read Records', ParentName: 'Home'}
      },
      {
        path: '/request_document',
        alias: '/request_document',
        component: () => import('../views/RequestDocumentsView.vue'),
        meta: { title: 'Request Document', ParentName: 'Home'}
      },
      {
        path: '/requested_records',
        alias: '/requested_records',
        component: () => import('../views/RequestedRecordsView.vue'),
        meta: { title: 'Requested Records', ParentName: 'Home'}
      },
      {
        path: '/records',
        alias: '/records',
        component: () => import('../views/RecordsView.vue'),
        meta: { title: 'Records', ParentName: 'Home'}
      },
      {
        path: '/my_requested_records',
        alias: '/my_requested_records',
        component: () => import('../views/MyRequestedRecordsView.vue'),
        meta: { title: 'My Requested Records', ParentName: 'Home'}
      },
      {
        path: '/archives',
        alias: '/archives',
        component: () => import('../views/ArchivesView.vue'),
        meta: { title: 'Archives', ParentName: 'Home'}
      },
      {
        path: '/inactives',
        alias: '/inactives',
        component: () => import('../views/InactiveView.vue'),
        meta: { title: 'Inactive Documents', ParentName: 'Home'}
      },
      {
        path: '/shared_records',
        alias: '/shared_records',
        component: () => import('../views/SharedRecordsView.vue'),
        meta: { title: 'Shared Records', ParentName: 'Home'}
      },
      {
        path: '/trash',
        alias: '/trash',
        component: () => import('../views/TrashView.vue'),
        meta: { title: 'Trash', ParentName: 'Home'}
      },
      {
        path: '/trash_documents',
        alias: '/trash_documents',
        component: () => import('../views/TrashDocumentsView.vue'),
        meta: { title: 'Trash Documents', ParentName: 'Home'}
      },
      {
        path: '/dispose',
        alias: '/dispose',
        component: () => import('../views/DisposeView.vue'),
        meta: { title: 'Disposed Files', ParentName: 'Home'}
      },
      //REPORTS
      {
        path: '/reports',
        alias: '/reports',
        component: () => import('../views/ReportsView.vue'),
        meta: { title: 'Reports', ParentName: 'Home'}
      },
      //UTILITY
      {
        path: '/accounts',
        alias: '/accounts',
        component: () => import('../views/AccountsView.vue'),
        meta: { title: 'Accounts', ParentName: 'Home'}
      },
      {
        path: '/utility_offices',
        alias: '/utility_offices',
        component: () => import('../views/UtilityOfficeView.vue'),
        meta: { title: 'Utility Offices', ParentName: 'Home'}
      },
      {
        path: '/utility_agency',
        alias: '/utility_agency',
        component: () => import('../views/UtilityAgencyView.vue'),
        meta: { title: 'Utility Agencies', ParentName: 'Home'}
      },
      {
        path: '/utility_suppliers',
        alias: '/utility_suppliers',
        component: () => import('../views/UtilitySupplierView.vue'),
        meta: { title: 'Utility Suppliers', ParentName: 'Home'}
      },
      {
        path: '/utility_finance_types',
        alias: '/utility_finance_types',
        component: () => import('../views/UtilityFinanceTypesView.vue'),
        meta: { title: 'Utility Finance Types', ParentName: 'Home'}
      },
      {
        path: '/utility_payee_types',
        alias: '/utility_payee_types',
        component: () => import('../views/UtilityPayeeTypesView.vue'),
        meta: { title: 'Utility Payee Types', ParentName: 'Home'}
      },
      {
        path: '/utility_file_types',
        alias: '/utility_file_types',
        component: () => import('../views/UtilityFileTypesView.vue'),
        meta: { title: 'Utility File Types', ParentName: 'Home'}
      },
      {
        path: '/utility_record_types',
        alias: '/utility_record_types',
        component: () => import('../views/UtilityRecordTypesView.vue'),
        meta: { title: 'Utility Record Types', ParentName: 'Home'}
      },
      {
        path: '/user_menu',
        alias: '/user_menu',
        component: () => import('../views/UtilityUserMenuView.vue'),
        meta: { title: 'Utility User Menu', ParentName: 'Home'}
      },
      {
        path: '/assign_user_menu',
        alias: '/assign_user_menu',
        component: () => import('../views/AssignUserTypeMenuView.vue'),
        meta: { title: 'Assign User Type', ParentName: 'Home'}
      },
      //TRANSACTIONS
      {
        path: '/transaction_finance',
        alias: '/transaction_finance',
        component: () => import('../views/TransactionFinanceView.vue'),
        meta: { title: 'Financial Transactions', ParentName: 'Home'}
      },
      {
        path: '/logs_transaction_finance',
        alias: '/logs_transaction_finance',
        component: () => import('../views/LogsTransactionFinance.vue'),
        meta: { title: 'Financial Transaction Logs', ParentName: 'Home'}
      },
      {
        path: '/communication_in/:isInternal',
        alias: '/communication_in/:isInternal',
        component: () => import('../views/LogsInCommunication.vue'),
        meta: { title: 'In Communication Logs', ParentName: 'Home'}
      },
      {
        path: '/communication_out/:isInternal',
        alias: '/communication_out/:isInternal',
        component: () => import('../views/LogsOutCommunication.vue'),
        meta: { title: 'Out Communication Logs', ParentName: 'Home'}
      },
      {
        path: '/campuswide_memorandums/:type',
        alias: '/campuswide_memorandums/:type',
        component: () => import('../views/CampusWideMemoView.vue'),
        meta: { title: 'Campus Wide Memorandums', ParentName: 'Home'}
      },
      {
        path: '/departmental_memorandums/:type',
        alias: '/departmental_memorandums/:type',
        component: () => import('../views/DepartmentalMemoView.vue'),
        meta: { title: 'Departmental Memorandums', ParentName: 'Home'}
      },
      {
        path: '/notice_of_meeting/:type',
        alias: '/notice_of_meeting/:type',
        component: () => import('../views/NOMView.vue'),
        meta: { title: 'Notice of Meetings', ParentName: 'Home'}
      },
      {
        path: '/special_orders/:type',
        alias: '/special_orders/:type',
        component: () => import('../views/SpecialOrdersView.vue'),
        meta: { title: 'Special Orders', ParentName: 'Home'}
      },
      {
        path: '/releasing_documents/:name',
        alias: '/releasing_documents/:name',
        component: () => import('../views/ReleasingDocumentsView.vue'),
        meta: { title: 'For Releasing Documents', ParentName: 'Home'}
      },
      {
        path: '/released_records/:name',
        alias: '/released_records/:name',
        component: () => import('../views/ReleasedDocumentsView.vue'),
        meta: { title: 'Released Documents', ParentName: 'Home'}
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  let role = store.state.user;
  if (to.matched.some(record => record.meta.Index)) {
      if (!role) {
          next({ path: '/rms'})
      } else {
        document.title = "RMS - " + to.meta.title;
        next()
      }
  }
  else {
    document.title = "RMS - " + to.meta.title;
      next()
  }
})

export default router
