import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './index.css';
import JsonExcel from "vue-json-excel3";
import { AnimateInViewDirective } from "@neeravp/vue-3-animate-in-view";
import vueDebounce from 'vue-debounce';

// createApp(App).use(store).use(router).mount('#app')

const app = createApp(App);

app
.directive('debounce', vueDebounce({ lock: true }))
.directive("animate-inview", AnimateInViewDirective)
.component("downloadExcel", JsonExcel)
.use(store)
.use(router)
.mount('#app')
